import { getWxSignation, getWxAgentSignation } from '@/api/workwchat'
import { Toast, Dialog } from 'vant'
import { wchatinfo, wchatbinding } from '@/api/workwchat'

// 配置微信
export const wxConfig = async function (permission, url) {
  const { data, success } = await getWxSignation(url)
  return new Promise(function (resolve) {
    if (success) {
      // 配置微信功能
      // eslint-disable-next-line no-undef
      wx.config({
        // beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        // debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: permission // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })
      // eslint-disable-next-line no-undef
      wx.ready(function (res) {
        // eslint-disable-next-line no-undef
        console.log('wxConfig:ok', res)
        resolve()
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      })
        // eslint-disable-next-line no-undef
        wx.error(function (res) {
        // eslint-disable-next-line no-undef
        console.log('wxConfig:error', res)
      })
    } else {
      Toast('配置失败')
    }
  })
}
// 配置微信应用
export const wxAgentConfig = async function (permission, url) {
  const { data, success } = await getWxAgentSignation(url)
  return new Promise(function (resolve) {
    if (success) {
      // 配置微信功能
      // eslint-disable-next-line no-undef
      wx.agentConfig({
        // beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        corpid: data.appId, // 必填，企业微信的corpID
        agentid: +data.agentId, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: permission, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        success: function (res) {
          // eslint-disable-next-line no-undef
          console.log('wxAgentConfig:ok', res)
          // 回调
          resolve()
        },
        fail: function (res) {
          // eslint-disable-next-line no-undef
          console.log('wxAgentConfig:error', res)
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级')
          }
        }
      })
    }
  })
}
// 获取外部联系人UID
export const wxgetCurExternalContact = async function () {
  return new Promise(function (resolve) {
    // eslint-disable-next-line no-undef
    wx.invoke('getCurExternalContact', {}, function (res) {
      console.log('获取外部联系人UID', res)
      if (res.err_msg === 'getCurExternalContact:ok') {
        resolve(res.userId)
      } else {
        if (res.err_msg.indexOf('without context of external contact') > -1) {
          Toast('请在聊天工具栏进入应用绑定用户')
        }
        // 错误处理
        resolve(false)
      }
    })
  })
}
// 获取客户绑定的userid
const getUserid = async (accountid) => {
  const res = await wchatinfo({ accountid })
  return new Promise((resolve, reject) => {
    if (res.success) {
      resolve(res.data.userid)
    } else reject()
  })
}
// 拉起聊天
const pullUpChat = async (userid, name) => {
  Toast.loading({
    message: 'Loading...',
    forbidClick: true,
    loadingType: 'spinner',
    overlay: true,
    duration: 0
  })
  const arrHref = document.location.href.split('#')
  await wxConfig(['openEnterpriseChat'], encodeURIComponent(arrHref[0]))
  // eslint-disable-next-line no-undef
  wx.openEnterpriseChat({
    // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
    userIds: '',
    externalUserIds: userid, // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
    groupName: name !== undefined ? name : '', // 会话名称。单聊时该参数传入空字符串""即可。
    // chatId: "CHATID", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
    success: function (res) {
      Toast.clear()
      // 返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
      // 回调
    },
    fail: function (res) {
      Toast.clear()
      // eslint-disable-next-line no-undef
      console.log('openEnterpriseChat:error', res)
      if (res.errMsg.indexOf('function not exist') > -1) {
        Toast('版本过低请升级')
      }
    }
  })
}
// 聊天功能--accountId
export const wxDoChat = async function (accountid, name) {
  Toast.loading({
    message: 'Loading...',
    forbidClick: true,
    loadingType: 'spinner',
    overlay: true,
    duration: 0
  })
  let userid = await getUserid(accountid)
  Toast.clear()
  if (!userid) {
    Dialog.confirm({
      title: '提示',
      message: '客户未绑定微信，是否绑定当前聊天微信？',
    }).then(async () => {
      Toast.loading({
        message: '绑定中...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true,
        duration: 0
      })
      const arrHref = document.location.href.split('#')
      await wxConfig(['selectExternalContact'], encodeURIComponent(arrHref[0]))
      await wxAgentConfig(['getCurExternalContact'], encodeURIComponent(arrHref[0]))
      userid = await wxgetCurExternalContact()
      if (userid) {
        const res = await wchatbinding({ userid, accountid })
        Toast.clear()
        if (res.success) {
          Toast.success('绑定成功')
          setTimeout(() => {
            pullUpChat(userid, name)
          }, 1000);
        } else {
          Toast(res.msg)
        }
      } else {
        Toast.clear()
        Toast('请在聊天工具栏进入应用绑定客户')
      }
    }).catch(() => {})
  } else pullUpChat(userid, name)
}
// 自定义转发到会话
export const share = async () => {
  const arrHref = document.location.href.split('#')
  await wxAgentConfig(['onMenuShareAppMessage'], encodeURIComponent(arrHref[0]))
  // eslint-disable-next-line no-undef
  wx.onMenuShareAppMessage({
    title: '巡展活动',
    desc: '巡展活动',
    link: 'https://web-hh.y.subway.i-driven.com.cn/eos-poster/',
    imgUrl: 'https://images.i-driven.com.cn//eos/88047201c62047e3891e392d2f1e36c3.png',
    success: function () {
      // eslint-disable-next-line no-undef
      console.log('分享成功')
    },
    cancel: function () {
      // eslint-disable-next-line no-undef
      console.log('取消分享')
    }
  })
}
// 处理发送信息
export const wxSendChatMessage = async function () {
  return new Promise(function (resolve) {
    // eslint-disable-next-line no-undef
    wx.invoke('sendChatMessage', {
      msgtype: 'miniprogram', // 消息类型，必填
      enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      miniprogram: {
        appid: 'wx460ff2ad9f82f7a9', // 小程序的appid
        title: 'this is title', // 小程序消息的title
        imgUrl: 'https://search-operate.cdn.bcebos.com/d054b8892a7ab572cb296d62ec7f97b6.png', // 小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
        page: '/pages/phoneLogin/index.html' // 小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
      }
    }, function (res) {
      if (res.err_msg === 'sendChatMessage:ok') {
        // 发送成功
        // eslint-disable-next-line no-undef
        console.log(res.err_msg)
        resolve()
      }
    })
  })
}
