export default [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login/index'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/financialCalculator',
    name: 'financialCalculator',
    component: () => import('../pages/financialCalculator/index'),
    meta: {
      title: '金融计算器'
    }
  }, {
    path: '/rank',
    name: 'rank',
    component: () => import('../pages/rank/index'),
    meta: {
      title: '排行榜'
    }
  }, {
    path: '/taoGuestPool',
    name: 'taoGuestPool',
    component: () => import('../pages/taoGuestPool/index'),
    meta: {
      title: '淘客池'
    }
  }, {
    path: '/taoGuestPoolAllot',
    name: 'taoGuestPoolAllot',
    component: () => import('../pages/taoGuestPoolAllot/index'),
    meta: {
      title: '分配'
    }
  }, {
    path: '/taoGuestPoolLongTerm',
    name: 'taoGuestPoolLongTerm',
    component: () => import('../pages/taoGuestPoolLongTerm/index'),
    meta: {
      title: '淘客池 Long Term'
    }
  }, {
    path: '/inventory',
    name: 'inventory',
    component: () => import('../pages/inventory/index'),
    meta: {
      title: '库存查询'
    }
  }, {
    path: '/chargingSearch',
    name: 'chargingSearch',
    component: () => import('../pages/chargingSearch/index'),
    meta: {
      title: '充电查询'
    }
  }, {
    path: '/allSearch',
    name: 'allSearch',
    component: () => import('../pages/allSearch/index'),
    meta: {
      title: '搜索'
    }
  }, {
    path: '/newCreate',
    name: 'newCreate',
    component: () => import('../pages/newCreate/index'),
    meta: {
      title: '新建'
    }
  }, {
    path: '/newCreateForm',
    name: 'newCreateForm',
    component: () => import('../pages/newCreateForm/index'),
    meta: {
      title: '新建-表单'
    }
  }, {
    path: '/businessAllot',
    name: 'businessAllot',
    component: () => import('../pages/businessAllot/index'),
    meta: {
      title: '商机下发分配'
    }
  }, {
    path: '/clueAllot',
    name: 'clueAllot',
    component: () => import('../pages/clueAllot/index'),
    meta: {
      title: '线索下发分配'
    }
  }, {
    path: '/poc',
    name: 'poc',
    component: () => import('../pages/poc/index'),
    meta: {
      title: 'POC'
    }
  }, {
    path: '/workbench',
    name: 'workbench',
    component: () => import('../pages/workbench/index'),
    meta: {
      title: 'Console'
    }
  }, {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../pages/orderList/index'),
    meta: {
      title: 'Orders'
    }
  }, {
    path: '/businessOpportunities',
    name: 'businessOpportunities',
    component: () => import('../pages/businessOpportunities/index'),
    meta: {
      title: 'Prospects'
    }
  }, {
    path: '/coPilot',
    name: 'coPilot',
    component: () => import('../pages/coPilot/index'),
    meta: {
      title: '待分配Co-Pilot'
    }
  }, {
    path: '/coPilotAllot',
    name: 'coPilotAllot',
    component: () => import('../pages/coPilotAllot/index'),
    meta: {
      title: '分配Co-Pilot'
    }
  }, {
    path: '/businessCard',
    name: 'businessCard',
    component: () => import('../pages/businessCard/index'),
    meta: {
      title: '名片'
    }
  }, {
    path: '/clues',
    name: 'clues',
    component: () => import('../pages/clues/index'),
    meta: {
      title: 'Leads'
    }
  }, {
    path: '/cluesFollowUp',
    name: 'cluesFollowUp',
    component: () => import('../pages/cluesFollowUp/index'),
    meta: {
      title: '跟进任务-线索跟进'
    }
  }, {
    path: '/businessOpportunitiesFollowUp',
    name: 'businessOpportunitiesFollowUp',
    component: () => import('../pages/businessOpportunitiesFollowUp/index'),
    meta: {
      title: '跟进任务-商机跟进'
    }
  }, {
    path: '/testDriveFollowUp',
    name: 'testDriveFollowUp',
    component: () => import('../pages/testDriveFollowUp/index'),
    meta: {
      title: '跟进任务-试驾跟进'
    }
  }, {
    path: '/testDriveList',
    name: 'testDriveList',
    component: () => import('../pages/testDriveList/index'),
    meta: {
      title: '试乘试驾-排程管理'
    }
  }, {
    path: '/testdriveSearch',
    name: 'testdriveSearch',
    component: () => import('../pages/testDriveSearch/index'),
    meta: {
      title: '试乘试驾-搜索排程'
    }
  }, {
    path: '/orderFollowUp',
    name: 'orderFollowUp',
    component: () => import('../pages/orderFollowUp/index'),
    meta: {
      title: '跟进任务-订单跟进'
    }
  }, {
    path: '/surveyFollowUp',
    name: 'surveyFollowUp',
    component: () => import('../pages/surveyFollowUp/index'),
    meta: {
      title: '跟进任务-勘测跟进'
    }
  }, {
    path: '/customerFollowUp',
    name: 'customerFollowUp',
    component: () => import('../pages/customerFollowUp/index'),
    meta: {
      title: '跟进任务-客户跟进'
    }
  }, {
    path: '/testDrive',
    name: 'testDrive',
    component: () => import('../pages/testDrive/index'),
    meta: {
      title: '试乘试驾'
    }
  }, {
    path: '/customerInfo',
    name: 'customerInfo',
    component: () => import('../pages/customerInfo/index'),
    meta: {
      title: '客户详情'
    }
  }, {
    path: '/followUpInfo',
    name: 'followUpInfo',
    component: () => import('../pages/followUpInfo/index'),
    meta: {
      title: '跟进详情'
    }
  }, {
    path: '/customerInfoEdit',
    name: 'customerInfoEdit',
    component: () => import('../pages/customerInfoEdit/index'),
    meta: {
      title: '编辑客户信息'
    }
  }, {
    path: '/redistribution',
    name: 'redistribution',
    component: () => import('../pages/redistribution/index'),
    meta: {
      title: '重新分配'
    }
  }, {
    path: '/surveyInfo',
    name: 'surveyInfo',
    component: () => import('../pages/surveyInfo/index'),
    meta: {
      title: '勘测单详情'
    }
  }, {
    path: '/createSurveyOrder',
    name: 'createSurveyOrder',
    component: () => import('../pages/createSurveyOrder/index'),
    meta: {
      title: '创建勘测单'
    }
  }, {
    path: '/cluesFollowUpFrom',
    name: 'cluesFollowUpFrom',
    component: () => import('../pages/cluesFollowUpFrom/index'),
    meta: {
      title: '线索跟进'
    }
  }, {
    path: '/businessOpportunitiesFollowUpFrom',
    name: 'businessOpportunitiesFollowUpFrom',
    component: () => import('../pages/businessOpportunitiesFollowUpFrom/index'),
    meta: {
      title: '商机跟进'
    }
  }, {
    path: '/weekDate',
    name: 'weekDate',
    component: () => import('../pages/weekDate/index'),
    meta: {
      title: '周例上下午'
    }
  }, {
    path: '/createOrder',
    name: 'createOrder',
    component: () => import('../pages/createOrder/index'),
    meta: {
      title: '创建订单'
    }
  },  {
    path: '/createQuoteOrder',
    name: 'createQuoteOrder',
    component: () => import('../pages/createQuoteOrder/index'),
    meta: {
      title: '创建报价订单'
    }
  }, {
    path: '/testDriveCreate',
    name: 'testDriveCreate',
    component: () => import('../pages/testDriveCreate/index'),
    meta: {
      title: '创建试驾排程'
    }
  }, {
    path: '/checkstand',
    name: 'checkstand',
    component: () => import('../pages/checkstand/index'),
    meta: {
      title: '收银台'
    }
  }, {
    path: '/testDriveDetail',
    name: 'testDriveDetail',
    component: () => import('../pages/testDriveDetail/index'),
    meta: {
      title: '试驾详情'
    }
  }, {
    path: '/testDriveOperatingRecord',
    name: 'testDriveOperatingRecord',
    component: () => import('../pages/testDriveDetail/operatingRecord'),
    meta: {
      title: '操作记录'
    }
  }, {
    path: '/configCar',
    name: 'configCar',
    component: () => import('../pages/configCar/index'),
    meta: {
      title: '配车'
    }
  }, {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../pages/orderDetail/index'),
    meta: {
      title: '订单详情'
    }
  }, {
    path: '/refound',
    name: 'refound',
    component: () => import('../pages/orderDetail/refound'),
    meta: {
      title: '申请退款'
    }
  }, {
    path: '/contract',
    name: 'contract',
    component: () => import('../pages/orderDetail/contract'),
    meta: {
      title: '合同签署'
    }
  }, {
    path: '/handOver',
    name: 'handOver',
    component: () => import('../pages/orderDetail/handOver'),
    meta: {
      title: 'Hand-over'
    }
  }, {
    path: '/buyInfoEdit',
    name: 'buyInfoEdit',
    component: () => import('../pages/orderDetail/buyInfoEdit'),
    meta: {
      title: '编辑购买信息'
    }
  }, {
    path: '/carOwnerEdit',
    name: 'carOwnerEdit',
    component: () => import('../pages/orderDetail/carOwnerEdit'),
    meta: {
      title: '编辑主用车人'
    }
  }, {
    path: '/accountDetails',
    name: 'accountDetails',
    component: () => import('../pages/accountDetails/index'),
    meta: {
      title: '账户详情'
    }
  }, {
    path: '/orderTotal',
    name: 'orderTotal',
    component: () => import('../pages/orderDetail/orderTotal'),
    meta: {
      title: '订单总计'
    }
  }, {
    path: '/priceDetail',
    name: 'priceDetail',
    component: () => import('../pages/orderDetail/priceDetail'),
    meta: {
      title: '价格明细'
    }
  }, {
    path: '/surveyOrder',
    name: 'surveyOrder',
    component: () => import('../pages/orderDetail/surveyOrder'),
    meta: {
      title: '勘测单'
    }
  }, {
    path: '/financialOrder',
    name: 'financialOrder',
    component: () => import('../pages/orderDetail/financialOrder'),
    meta: {
      title: '金融单'
    }
  }, {
    path: '/createFinancialOrder',
    name: 'createFinancialOrder',
    component: () => import('../pages/createFinancialOrder/index'),
    meta: {
      title: '创建金融单'
    }
  }, {
    path: '/benefit',
    name: 'benefit',
    component: () => import('../pages/orderDetail/benefit'),
    meta: {
      title: '权益'
    }
  }, {
    path: '/moneyOrder',
    name: 'moneyOrder',
    component: () => import('../pages/orderDetail/moneyOrder'),
    meta: {
      title: '资金单'
    }
  }, {
    path: '/modifyConfig',
    name: 'modifyConfig',
    component: () => import('../pages/orderDetail/modifyConfig'),
    meta: {
      title: '修改配置'
    }
  }, {
    path: '/person',
    name: 'person',
    component: () => import('../pages/person/index'),
    meta: {
      title: 'Profile'
    }
  }, {
    path: '/bindingUserId',
    name: 'bindingUserId',
    component: () => import('../pages/workbench/bindingUserId'),
    meta: {
      title: '绑定UserId'
    }
  }, {
    path: '/shareMiniProgram',
    name: 'shareMiniProgram',
    component: () => import('../pages/workbench/shareMiniProgram'),
    meta: {
      title: '分享应用'
    }
  },{
    path: "/DepositReceipt",
    name: "DepositReceipt",
    component: () => import("../pages/DepositReceipt/index.vue"),
    meta: {
      title: "Deposit Receipt"
    }
  },{
    path: "/Quote",
    name: "Quote",
    component: () => import("../pages/Quote/index.vue"),
    meta: {
      title: "Quote"
    }
  }
]