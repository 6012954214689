import Api from '^'
const { M_BASE_PATH, M_TESTDRIVE_PATH, M_ENTERTAIN_PATH, M_ORDERCENTER_PATH, M_GENERAL_PATH, M_SYSMANAGE_PATH, M_SERVEORDERCENTER_PATH, M_LEADSOPP_PATH, M_SYSMANAGEMENT_PATH, M_USER_PATH } = Api

export default {
  //通用板块
  general: {
    basedatas: M_GENERAL_PATH.concat('basedatas'), // 获取basedata数据字典
    accountinfo: M_GENERAL_PATH.concat('accountinfo'), // 获取basedata数据字典
    accountupdate: M_GENERAL_PATH.concat('accountupdate') // 编辑客户信息
  },
  demo1: {
    list: M_TESTDRIVE_PATH.concat('/vehicle/list'),
  },
  user: {
    login: M_BASE_PATH.concat('workwchatlogin'),
    getToken: M_BASE_PATH.concat('idm/callback'), // 获取account_no和token
    getUserInfo: M_BASE_PATH.concat('sysmanagement/user/idm'), // 获取用户信息
    getTokenAndAccoun: M_BASE_PATH.concat('getbyuserid') // 根据用户id获取token以及account
  },
  system: {
    storeList: M_SYSMANAGEMENT_PATH.concat('organization/store/list'), // 获取门店
    getStoreList: M_SYSMANAGEMENT_PATH.concat('organization/store/list'), // 获取门店
    orgRoleList: M_SYSMANAGEMENT_PATH.concat('user'), // 用户组织角色管理/用户组织角色列表
    changeRole: (M_BASE_PATH).concat('changeRole'), // 切换登陆角色或组织
    loginOut: (M_BASE_PATH).concat('logout'), // 退出登陆
    userListAll: M_BASE_PATH.concat('user/list'),
    roleAuthority: M_SYSMANAGEMENT_PATH.concat('role'), // 获取当前角色权限
  },
  common: {
    aliyunOSS: M_BASE_PATH.concat('aliyun/sts/oss'),
    getChargeSearch: M_GENERAL_PATH.concat('charging-pile/list'), // 充电站查询
    areatree: M_BASE_PATH.concat('general/areatree'), // 获取行政区域
    accountSearch: M_GENERAL_PATH.concat('accountsearch'), //account搜索
    oppSearch: M_GENERAL_PATH.concat('oppsearch'), // opp搜索
    LeadsSearch: M_GENERAL_PATH.concat('leadssearch'), // Leads搜索
    taokePoll: M_LEADSOPP_PATH.concat('leadstkpool'), // 淘客池线索
    taokeClaim: M_BASE_PATH.concat('leadsopp/leadsapply'), // 淘客池-线索-认领
    taokePollopp: M_LEADSOPP_PATH.concat('opplongterms'), // 淘客池线索(opp-LongTerm)
    oppsList: M_LEADSOPP_PATH.concat('opps'), // 商机列表
    orderList: M_ORDERCENTER_PATH.concat('order/orderlist'), // 订单列表
    clueFollow: M_LEADSOPP_PATH.concat('leadsfollowup'), // 线索跟进
    oppFollowUp: M_LEADSOPP_PATH.concat('oppfollowup'), // 商机跟进
    leadsList: M_LEADSOPP_PATH.concat('leads'),// 线索列表
    oppSendList: M_LEADSOPP_PATH.concat('oppsendlist'),//商机下发分配
    leadsSendList: M_LEADSOPP_PATH.concat('leadssendlist'), // 线索下发分配列表
    leadssend: M_LEADSOPP_PATH.concat('leadssend'), // 线索下发分配动作
    oppsend: M_LEADSOPP_PATH.concat('oppsend'), // 商机下发分配动作 
    getAreaTree: M_BASE_PATH.concat('general/areatree'), // 获取省市数据
    customerprofile: M_BASE_PATH.concat('general/customerprofile'), // 获取用户档案信息选项
    organization: M_SYSMANAGE_PATH.concat('organization/tree'), // 组织-获取组织树
    userList: M_USER_PATH.concat('list'), // 组织-获取组织下用户列表
    carseries: M_ORDERCENTER_PATH.concat('carseries'), //工程车型
    carbrandcity: M_GENERAL_PATH.concat('carbrandcity'), // 上牌城市
    orderDetaile: M_SERVEORDERCENTER_PATH.concat('order/detailed'), // 订单详情
    topLeads: M_BASE_PATH.concat('top/leads'), // 排行榜/线索
    topOpp: M_BASE_PATH.concat('top/opp'), // 排行榜/商机
    topDr: M_BASE_PATH.concat('top/dr'), // 排行榜/意向金净增
    topOrder: M_BASE_PATH.concat('top/order'), // 排行榜/大定净增
    topTDCompleted: M_BASE_PATH.concat('top/td-completed'), // 排行榜/试乘试驾完成
    topTDNoshow: M_BASE_PATH.concat('top/td-no-show'), // 排行榜/TD No-show
    oppSave: M_LEADSOPP_PATH.concat('opppocsave'), // poc保存
    userlist: M_BASE_PATH.concat('idm/user/list'), // 获取IDM用户列表
    leadstksend: M_LEADSOPP_PATH.concat('leadstksend'), // 淘客池线索下发分配动作,
    getEntertainment: M_BASE_PATH.concat('entertainment/list'), // 获取活动列表
    orgCode: M_BASE_PATH.concat('voicecall/getVoicecall'), // 拨打电话
    getList: M_BASE_PATH.concat('entertainment/list'), // 活动列表
    getOppInfo: M_BASE_PATH.concat('general/oppinfo'), // 获取商机详情
    getleadsinfo: M_BASE_PATH.concat('general/leadsinfo'), // 获取线索跟进详情
    channelList: M_BASE_PATH.concat('general/channeldata'), // 获取渠道信息
    trendnew: M_BASE_PATH.concat('general/trendnew') // 动向更新
  },
  // 创建试乘试驾模块
  testdrive: {
    list: M_TESTDRIVE_PATH.concat('vehicle/list'), // 获取试驾车列表
    storeList: M_SYSMANAGE_PATH.concat('organization/store/list'),// 获取门店列表
    carList: M_TESTDRIVE_PATH.concat('vehicle/list'), // 获取试驾车列表
    activetyList: M_ENTERTAIN_PATH.concat('list'), // 试驾活动列表
    poiltList: M_SYSMANAGE_PATH.concat('store/userList'), // 创建时获取陪同人员列表
    account: M_GENERAL_PATH.concat('leadsaccount'), //创建时根据手机号码搜索用户
    checkTime: M_TESTDRIVE_PATH.concat('statistics'), // 根据选择的门店/时间/车辆获取当前车辆的可选时间
    createTestDrive: M_TESTDRIVE_PATH, //创建试驾排程 修改试驾排程以及获取排程详情
    searchTestdrive: M_TESTDRIVE_PATH.concat('search'), // 根据手机号搜索排程
    logList: M_TESTDRIVE_PATH.concat('log/list'), // 获取试驾操作记录
    changeStatus: M_TESTDRIVE_PATH.concat('status'),// 修改排程状态
    unPoilt: M_TESTDRIVE_PATH.concat('undistributed/list'),// 获取待分配poilt列表
    changePoilt: M_TESTDRIVE_PATH.concat('co-pilot')// 分配poilt人员
  },
  order: {
    detail: M_ORDERCENTER_PATH.concat('order/detailed'), // 订单详情
    loanorderlist: M_ORDERCENTER_PATH.concat('order/loanorderlist'), // 查询金融单
    editorder: M_ORDERCENTER_PATH.concat('order/editorder'), // 修改订单
    mainowner: M_ORDERCENTER_PATH.concat('order/mainowner'), // 预设主用车人
    handoff: M_ORDERCENTER_PATH.concat('order/handoff'), // HandOff
    refund: M_ORDERCENTER_PATH.concat('order/refund'), // 申请退款小订订单
    preassign: M_ORDERCENTER_PATH.concat('order/preassign'), // 配车
    orderList: M_ORDERCENTER_PATH.concat('order/orderlist'), // 订单列表
    cartype: M_ORDERCENTER_PATH.concat('cartype'), // 车型产品-基础车型
    carfeatures: M_ORDERCENTER_PATH.concat('carfeatures'), // 车型产品-选装特征
    carconstraint: M_ORDERCENTER_PATH.concat('carconstraint'), // 车型产品-约束规则
    createOrder: M_ORDERCENTER_PATH.concat('order/createorder'), // 订单新增
    getCarprice: M_ORDERCENTER_PATH.concat('carprice'), // 车辆编码及价格
    inventoryList: M_ORDERCENTER_PATH.concat('carlist'), // 库存列表
    basicModels: M_ORDERCENTER_PATH.concat('cartype'), // 库存列表-基础车型
    engineeringModels: M_ORDERCENTER_PATH.concat('carseries'), // 库存列表-工程车型
    getLoan: M_ORDERCENTER_PATH.concat('order/loan'), // 金融方案
    addloanorder: M_ORDERCENTER_PATH.concat('order/addloanorder'), // 创建金融单
    getUserList: M_BASE_PATH.concat('user/list'), // 订单列表 - 出行顾问
    orgList: M_ORDERCENTER_PATH.concat('orglist'), // 门店组织List API 及 交付中心
    area: M_ORDERCENTER_PATH.concat('area'), // 获取城市信息
    payment: M_ORDERCENTER_PATH.concat('payment/unitorder/pay'), // 支付宝和微信二维码支付
    alterconfigure: M_ORDERCENTER_PATH.concat('order/alterconfigure'), // 订单改配
    getCarseries: M_ORDERCENTER_PATH.concat('carseries'), // 获取工程车型
    transtoorder: M_ORDERCENTER_PATH.concat('order/transtoorder'), // 转大定
    surveyDetail: M_BASE_PATH.concat('survey/followup/constructionOrder/queryByConstructionOrderMessage'), // 获取勘测单详情
    offlineRegistration: M_ORDERCENTER_PATH.concat('order/offlineRegistration'), // 合同保存
    contractWithdraw: M_ORDERCENTER_PATH.concat('contract/withdraw'), // 合同撤回
    allStart: M_BASE_PATH.concat('survey/followup/scheduling/findAllStar'), // 创建勘测单时获取服务明星
    createSurvey: M_BASE_PATH.concat('survey/followup/constructionOrder'), // 创建勘测单
    leadsPriceOpp: M_BASE_PATH.concat("leadsopp/leadsPriceOpp"),
    shareData: M_BASE_PATH.concat("leadsopp/priceOrder/shareData"),
    otdresults: M_ORDERCENTER_PATH.concat("payment/otdresults") // 外围支付系统将支付结果和详情发给SMP
  },
  // 线索&商机
  clue: {
    leadssearch: M_BASE_PATH.concat('general/leadssearch'), // 新建搜索-Leads搜索
    getEntertainment: M_BASE_PATH.concat('entertainment/list'), // 获取商机活动
    oppsList: M_BASE_PATH.concat('leadsopp/opps'), // 商机列表
    leadssave: M_LEADSOPP_PATH.concat('leadssave'),
    testdriveSearch: M_BASE_PATH.concat('testdrive/search'), // 试驾试乘
    joinActive: M_BASE_PATH.concat('entertainment/campaignlistbyaccountid'), // 参与活动
    OrderMessage: M_BASE_PATH.concat('survey/followup/constructionOrder/queryByConstructionOrderMessage'), // 勘测单
    accounttrend: M_BASE_PATH.concat('account/accounttrend'), // 客户动向
    leadsapply: M_BASE_PATH.concat('leadsopp/leadsapply'), // 搜索页面的认领
    accountDetail: M_BASE_PATH.concat('general/accountinfo'), // 获取客户详情
    pocInfo: M_BASE_PATH.concat('leadsopp/opppocinfo'), // 获取poc详情
    orderMessage: M_BASE_PATH.concat('survey/followup/constructionOrder/queryByConstructionOrderMessage'), // 获取勘测单
    ledasoppLeads: M_BASE_PATH.concat('leadsopp/leads'), // 客户详情页线索列表
    leadsfollowuplist: M_BASE_PATH.concat('leadsopp/leadsfollowuplist'), // 线索跟进记录
    oppfollowupList: M_BASE_PATH.concat('leadsopp/oppfollowuplist'), // 商机跟进记录
    leadsagainsend: M_BASE_PATH.concat('leadsopp/leadsagainsend'), // 线索重新分配
    oppsagainsend: M_BASE_PATH.concat('leadsopp/oppagainsend'), // 商机重新分配
    leadsbydrsave: M_BASE_PATH.concat('leadsopp/leadsbydrsave'),
    leadsbyordersave: M_BASE_PATH.concat('leadsopp/leadsbyordersave')
  },
  // 工作台
  workbench: {
    taskCount: M_BASE_PATH.concat('general/followuptaskcount'), // 获取工作台跟进数量
    rankList: M_BASE_PATH.concat('workbench/increment-ranking'), // 获取战绩排名数据
    unDistribution: M_BASE_PATH.concat('general/undistributedcount'), // 获取工作台待分配数量
    getSalesFunnelStatistics: M_BASE_PATH.concat("general/getSalesFunnelStatistics")
  },
  // 跟进任务
  follow: {
    leads: {
      todayList: M_BASE_PATH.concat('leadsopp/leadsfollowuptoday'), // 线索-今日待跟进
      waitContactList: M_BASE_PATH.concat('leadsopp/leadsfollowupwaitcontact'), // 待联系
      dormantList: M_BASE_PATH.concat('leadsopp/leadsfollowupdormant') // 休眠Leads
    },
    ledasopp: {
      todayList: M_BASE_PATH.concat('leadsopp/oppfollowuptoday'), // 商机-今日待跟进
      closeDateList: M_BASE_PATH.concat('leadsopp/oppfollowupclosedate'), // CloseDate
      unorderList: M_BASE_PATH.concat('leadsopp/oppfollowupunorder'), // 89 未成交
      hibernateList: M_BASE_PATH.concat('leadsopp/oppfollowuphibernate') // 休眠Opp
    },
    testdrive: {
      todayList: M_BASE_PATH.concat('testdrive/followup/today'), // 试驾-今日试驾
      pdcList: M_BASE_PATH.concat('testdrive/followup/pdc'), // PDC以及pdc完成任务
      noShowList: M_BASE_PATH.concat('testdrive/followup/no-show'), // No-show
      tdFollowUpList: M_BASE_PATH.concat('leadsopp/opptdfollowup') // No-show
    },
    order: {
      depositPaidList: M_BASE_PATH.concat('order/followup/deposit-paid'), // 订单跟进-大定已付待跟进
      handOffList: M_BASE_PATH.concat('order/followup/hand-off'), // 订单跟进-Hand-off
      drRefundList: M_BASE_PATH.concat('order/followup/dr-refund') // 订单跟进-意向金退款待跟进
    },
    survey: {
      breakOffList: M_BASE_PATH.concat('survey/followup/break-off') // 勘测单跟进-勘测中止
    },
    account: {
      endList: M_BASE_PATH.concat('account/accountfollowuptrend'), // 客户跟进-客户动向
      notToShopList: M_BASE_PATH.concat('account/accountfollowupnottoshop'), // 客户跟进-未进店
      finishClient: M_BASE_PATH.concat('account/accountfollowuptrenddone') // 客户动向- 完成任务
    }
  },
  workwchat: {
    getWxSignation: M_BASE_PATH.concat('/workwchat/configinfoc'), // 企业微信config
    getWxAgentSignation: M_BASE_PATH.concat('/workwchat/configinfo'), // 企业应用agentConfig
    accountList: M_BASE_PATH.concat('/account/list'), // account客户列表
    wchatbinding: M_BASE_PATH.concat('/account/wchatbingding'), // 客户绑定userId
    wchatinfo: M_BASE_PATH.concat('/account/wchatinfo') // 获取客户绑定userId
  }
}
