export default {
  set(key, value) {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, value)
    }
  },
  get(key) {
    const value = localStorage.getItem(key)
    try {
      return JSON.parse(value)
    } catch(e) {
      // console.log(e)
    }
    
    return value
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  }
}