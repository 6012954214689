import request from '@/utils/request'
import Api from '@/api/base/api-config'

export const getWxSignation = (url) => {
  return request({
    method: 'get',
    url: `${Api.workwchat.getWxSignation}?url=${url}`,
  })
}

export const getWxAgentSignation = (url) => {
  return request({
    method: 'get',
    url: `${Api.workwchat.getWxAgentSignation}?url=${url}`,
  })
}

export const accountList = (params) => {
  return request({
    method: 'get',
    url: Api.workwchat.accountList,
    params
  })
}

export const wchatbinding = (params) => {
  return request({
    method: 'post',
    url: Api.workwchat.wchatbinding,
    data: params
  })
}

export const wchatinfo = (params) => {
  return request({
    method: 'get',
    url: Api.workwchat.wchatinfo,
    params
  })
}