import storage from '@/utils/storage'

/** 
 * 权限指令
 * 使用方法 v-auth="'资源code'" 或者 v-auth="'父级资源code,子级资源code'"
*/
export default {
  inserted (el, binding) {
    const authorized = storage.get('authorized')
    const value = binding.value
    const resCodeList = value ? value.split(',') : []
    if (!authorized || resCodeList.some(code => !authorized.includes(code))) {
      el.remove()
    }
  }
}