const M_BASE_PATH = process.env.NODE_ENV === 'development' ? '/api-core-back/' : 'https://web-hh.i-driven.com.cn/api-core-back/api-web/'
// const M_BASE_PATH = 'https://eos.productization.i-driven.com.cn/feos-application-web-backend/'

// 根据不同接口配置相应的路径
const M_SERVEORDERCENTER_PATH = M_BASE_PATH.concat('/smp-agg-order-service/v1/otd/ordercenter/')
const M_TESTDRIVE_PATH = M_BASE_PATH.concat('testdrive/')
const M_GENERAL_PATH = M_BASE_PATH.concat('general/')
const M_LEADSOPP_PATH = M_BASE_PATH.concat('leadsopp/')
const M_ENTERTAIN_PATH = M_BASE_PATH.concat('entertainment/')
const M_SYSMANAGE_PATH = M_BASE_PATH.concat('sysmanagement/')
const M_ORDERCENTER_PATH = M_BASE_PATH.concat('v1/otd/ordercenter/')
const M_SYSMANAGEMENT_PATH = M_BASE_PATH.concat('sysmanagement/')
const M_USER_PATH = M_BASE_PATH.concat('user/')
export default {
  M_TESTDRIVE_PATH,
  M_GENERAL_PATH,
  M_LEADSOPP_PATH,
  M_BASE_PATH,
  M_ORDERCENTER_PATH,
  M_ENTERTAIN_PATH,
  M_SYSMANAGE_PATH,
  M_SYSMANAGEMENT_PATH,
  M_SERVEORDERCENTER_PATH,
  M_USER_PATH,
  path: {
    api: M_BASE_PATH
  },
  sso: {
    auth_url: 'https://open.weixin.qq.com/connect/oauth2/authorize',
    corpID: 'wwbbdbb74f2aeef7ea',
    redirect_uri: 'https://web-hh.i-driven.com.cn/api-core-back/workwchat/oauth',
    login_url: 'https://web-hh.i-driven.com.cn/eos-workweixin-app'
  }
}
