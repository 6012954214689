<template>
  <div
    id="app-container"
    :style="appStyle"
  >
    <div
      v-if="showHeader"
      id="header"
      :style="headerStyle"
    >
      <div class="header-navbar">
        <NavBar
          v-bind="$attrs"
          @click-left="onClickLeft"
          @click-right="onClickRight"
        >
          <template
            v-if="$slots.left"
            slot="left"
          >
            <slot name="left" />
          </template>
          <template
            v-if="$slots.title"
            slot="title"
          >
            <slot name="title" />
          </template>
          <template
            v-if="$slots.right"
            slot="right"
          >
            <slot name="right" />
          </template>
        </NavBar>
      </div>
      <div
        v-if="$slots.extra"
        class="header-extra"
      >
        <slot name="extra" />
      </div>
    </div>
    <div
      id="container"
      :style="containerStyle"
    >
      <slot />
    </div>
    <div
      v-if="$slots.footer || showFooterTabBar"
      id="footer"
    >
      <slot name="footer" />
      <FooterTab
        v-if="showFooterTabBar"
        :activeName="activeName"
      />
    </div>
  </div>
</template>
<script>
import { NavBar } from "vant";
import FooterTab from "../footerTab/index.vue";
export default {
  name: "App",
  components: {
    NavBar,
    FooterTab
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: "#F2F2F6"
    },
    showFooterTabBar: {
      type: Boolean,
      default: false
    },
    activeName: {
      type: String,
      default: "workbench"
    }
  },
  data () {
    return {
      headerHeight: 0,
      footerHeight: 0,
      safeArea: {}
    };
  },
  computed: {
    appStyle () {
      return {
        "background-color": this.bgColor
      };
    },
    headerStyle () {
      return {
        "background-color": "#fff"
      };
    },
    containerStyle () {
      return {
        "padding-top": this.headerHeight + "px",
        "padding-bottom": this.footerHeight + "px"
      };
    }
  },
  methods: {
    onClickLeft () {
      if (Reflect.has(this.$listeners, "click-left")) {
        this.$emit("click-left", () => {
          this.$router.go(-1);
        });
      } else {
        this.$router.go(-1);
      }
    },
    onClickRight () {
      this.$emit("click-right");
    },
    observerHeaer () {
      const header = document.getElementById("header");
      if (header) {
        const observer = new MutationObserver(() => {
          if (header.offsetHeight) {
            this.headerHeight = header.offsetHeight;
          }
        });
        observer.observe(header, {
          subtree: true,
          childList: true
        });
        this.headerHeight = header.offsetHeight;
      }
    },
    observerFooter () {
      const footer = document.getElementById("footer");
      if (footer) {
        const observer = new MutationObserver(() => {
          if (footer.offsetHeight) {
            this.footerHeight = footer.offsetHeight;
          }
        });
        observer.observe(footer, {
          subtree: true,
          childList: true
        });
        this.footerHeight = footer.offsetHeight;
      }
    }
  },
  mounted () {
    this.observerHeaer();
    this.observerFooter();
  }
};
</script>
<style lang="less" scoped>
#app-container {
  min-height: 100vh;
  margin: 0 auto;
  font-size: 12px;
  color: #000;
  position: relative;
  
  .nav-bar-arrow-size {
    font-size: 20px;
  }
  #header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
  }

  #container {
    position: relative;
    box-sizing: border-box;
    min-height: 100vh;
    padding: 0 24px;
  }

  #footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 50;
  }
}
// .van-icon .van-icon-arrow-left .van-nav-bar__arrow{
// font-size: 25px !important;
// }
</style>
