
// business code
export const BUSINESS_SUCCESS_CODE = '000000'
export const BUSINESS_INVALID_CODE = '100002'
export const BUSINESS_ERROR_CODE_MSG = {
  100002: '您未登录，或者登录已经超时，请先登录！',
  DEFAULT: '请求失败，请稍后重试或联系管理员！'
}

// HTTP code
export const HTTP_ERROR_CODE_MSG = {
  400: '请求参数错误！',
  401: '您未登录，或者登录已经超时，请先登录！',
  403: '您没有操作权限！',
  404: '请求资源未找到！',
  408: '网络请求超时，请稍后重试！',
  NO_NETWORK: '网络异常！',
  DEFAULT: '服务异常，请联系管理员！'
}