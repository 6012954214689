import axios from 'axios'
import { Dialog, Toast } from 'vant'
import isDef from 'lodash-es/isUndefined'
import { 
  HTTP_ERROR_CODE_MSG, 
  BUSINESS_SUCCESS_CODE, 
  BUSINESS_INVALID_CODE,
  BUSINESS_ERROR_CODE_MSG 
} from './constant'
import storage from './storage'
import router from '../router'

// 处理重复请求
const cancelTokenManager = {
  _map: new Map(),
  getKey(config) {
    const { url, method } = config
    return [url, method].join('-')
  },
  add(config) {
    const key = this.getKey(config)
    config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
      if (!this._map.has(key)) {
        this._map.set(key, cancel)
      }
    })
  },
  remove(config) {
    const key = this.getKey(config)
    if (this._map.has(key)) {
      const cancel = this._map.get(key)
      cancel()
      this._map.delete(key)
    }
  }
}

// 处理授权
const authManager = {
  set(config) {
    config.headers.token = storage.get('token')
    config.headers.account_no = storage.get('account_no')
  },
  remove() {
    storage.remove('token')
    storage.remove('account_no')
  }
}

// 处理loading
const loadingManager = {
  _count: 0, // 用来计数当前的请求数
  _instance: null, // 保存loading实例
  open(config) {
    if (!this._instance && this._count === 0) {
      /* this._instance = Toast.loading({
        message: config.__loading_content,
        forbidClick: true,
        loadingType: 'spinner'
      }) */
    }
    this._count++
  },
  close() {
    if (this._instance && this._count === 1) {
      this._instance.clear()
      this._instance = null
    }
    this._count--
  }
}

// 自定义配置(使用双下划线__开头)
const customConfig = {
  __loading: false, // 是否开启loading
  __loading_content: 'loading' // loading 提示内容
}

/**
 * 
 * @param {*} options axios config
 * @param {*} customOptions custom Config
 */
export default function request(options, customOptions = {}) {
  const service = axios.create({
    // timeout: options.url === '/eos-application-web-backend/leadsopp/leads?page=1&size=3' ? 1 : 10000
    timeout: 100000
  })

  service.interceptors.request.use(config => {
    // 合并自定义配置
    Object.assign(config, { ...customConfig, ...customOptions }, config)

    cancelTokenManager.remove(config)
    cancelTokenManager.add(config)
    authManager.set(config)
    config.__loading && loadingManager.open(config)

    return config
  }, error => {
    return Promise.reject(error)
  })

  service.interceptors.response.use(response => {
    cancelTokenManager.remove(response.config)
    customOptions.__loading && loadingManager.close()

    const code = response.data.code
    if (code === BUSINESS_SUCCESS_CODE) {
      // 标识当前请求为成功请求
      response.data.success = true
    } else if (code === BUSINESS_INVALID_CODE) {
      businessErrorHandle(code)
      authManager.remove()
      router.push('/login')
    } else {
      businessErrorHandle(code)
    }
    return response.data
  }, error => {
    customOptions.__loading && loadingManager.close()
    // if (!axios.isCancel(error)) {
    //   error.config && cancelTokenManager.remove(error.config)
    //   httpErrorHandle(error)
    // }
    let response = {}
    if (error.request.status === 0) {
      response = {
        traceId: '',
        respTime: new Date(),
        success: false,
        stateCode: 0,
        stateDesc: 'fail，stateCode:0',
        stateDetail: error
      }
    }
    console.log('responseError=================', response)
    Promise.reject(error)
    return Promise.resolve(response)
  })
  return service(options)
}


// 业务级别的异常
function businessErrorHandle(code) {
  let msg = ''
  if (BUSINESS_ERROR_CODE_MSG[code]) {
    msg = BUSINESS_ERROR_CODE_MSG[code]
  } else {
    // 默认错误信息
    msg = BUSINESS_ERROR_CODE_MSG.DEFAULT
  }
  Dialog({ message: msg })
}

// http请求异常处理
function httpErrorHandle(error) {
  const status = error.response.status

  let msg = ''
  if (HTTP_ERROR_CODE_MSG[status]) {
    msg = HTTP_ERROR_CODE_MSG[status]
    if (status === 401) authManager.remove()
  } else if (isDef(window.navigator.onLine) && !window.navigator.onLine) {
    // 断网
    msg = HTTP_ERROR_CODE_MSG.NO_NETWORK
  } else {
    // 默认错误信息
    msg = HTTP_ERROR_CODE_MSG.DEFAULT
  }

  Dialog({ message: msg })
}

// 为了处理重复提交请求和loading而封装的方法
export function requestHandler (fn, ...args) {
  /* const toast = Toast.loading({
    message: 'loading...',
    forbidClick: true,
    loadingType: 'spinner'
  }) */
  return fn.apply(this, args).finally(() => {
    // toast.clear()
  })
}