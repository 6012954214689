// 通用正则
export default {
  mobile: {
    reg: /^(((13[0-9]{1})|(17[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/,
    msg: '手机号码格式不正确'
  },
  cardNo: {
    reg: /^[a-zA-Z0-9]{1,20}$/,
    msg: '证件号码格式不正确'
  },
  num: {
    reg: /^[0-9]{1,30}$/,
    msg: '手机号码格式不正确'
  }
}