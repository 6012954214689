export const footerTabList = [
  {
    icon: require('../../assets/images/icon/footer-workbanch@2x.png'),
    activeIcon: require('../../assets/images/icon/footer-workbanch-active@2x.png'),
    title: 'Console',
    name: 'workbench',
    url: 'workbench/index',
    class: 'footer-icon',
    authCode: ''
  },
  {
    icon: require('../../assets/images/icon/footer-clue@2x.png'),
    activeIcon: require('../../assets/images/icon/footer-clue-active@2x.png'),
    title: 'Leads',
    name: 'clues',
    url: 'clues/index',
    class: 'footer-icon',
    authCode: 'Leads-List'
  },
  {
    icon: require('../../assets/images/icon/footer-add@2x.png'),
    activeIcon: require('../../assets/images/icon/footer-add@2x.png'),
    title: '',
    name: 'newCreate',
    url: 'newCreate/index',
    class: 'footer-icon-add',
    authCode: 'workbench-NewAddList'
  },
  {
    icon: require('../../assets/images/icon/footer-opp@2x.png'),
    activeIcon: require('../../assets/images/icon/footer-opp-active@2x.png'),
    title: 'Prospects',
    name: 'businessOpportunities',
    url: 'businessOpportunities/index',
    class: 'footer-icon',
    authCode: 'Opp-List'
  },
  {
    icon: require('../../assets/images/icon/footer-open@2x.png'),
    activeIcon: require('../../assets/images/icon/footer-open-active@2x.png'),
    title: 'Profile',
    name: 'person',
    url: 'person/index',
    class: 'footer-icon',
    authCode: ''
  }
]