import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes'
import { getToken } from '../utils/auth'

Vue.use(Router)
const router = new Router({
  routes
})
const whiteList = ["DepositReceipt", "Quote"]
router.beforeEach((to, from, next) => {
  if(whiteList.includes(to.name)) {
    next()
  } else {
    const token = getToken()
  
    if (!token) {
      if (to.name !== 'login') {
        return next('/login')
      }
    } else {
      if (to.name === 'login') {
        return next('/workbench')
      }
    }
    next()
  }

})

export default router