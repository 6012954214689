<template>
  <div class="footer-tab">
    <div
      class="footer-tab-item"
      v-for="(item, index) in list"
      :key="index"
      v-auth="item.authCode"
      @click="goPath(item.url, item.name)"
    >
      <div>
        <img
          :src="activeName === item.name ? item.activeIcon : item.icon"
          :class="item.class"
          alt=""
        />
      </div>
      <div :class="activeName === item.name ? 'activeTitle' : 'activeTitle2'">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { footerTabList } from "./attr.js";
export default {
  data() {
    return {
      list: [...footerTabList],
    };
  },
  props: {
    activeName: {
      type: String,
      default: "workbench",
    },
  },
  methods: {
    goPath(url, name) {
      if (name !== "person") {
        {
          this.$router.push({ name, winOpts: { reload: true } });
        }
      } else {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer-tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background: #fff;
  padding: 24px;
  font-size: 24px;
  box-shadow: 0 -3px 3px #eee;
  .footer-tab-item {
    .footer-icon {
      width: 40px;
      height: 40px;
      position: relative;
      top: -10px;
    }
    .footer-icon-add {
      width: 92px;
      height: 92px;
      position: relative;
      top: -10px;
    }
    .activeTitle {
      color: #DA3738;
      position: relative;
      top: -10px;
    }
    .activeTitle2 {
      position: relative;
      top: -10px;
    }
  }
}
</style>