import Vue from 'vue';
import { Icon } from 'vant';
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'
import AppContainer from './components/App/index.vue'
import regexp from './config/regexp'
import directives from './directives'
import { requestHandler } from './utils/request'
import storage from './utils/storage'
import router from './router'
import 'lib-flexible/flexible'
import 'vant/lib/index.less'
import '@/style/common.less'
import '@/style/var.less'
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);
import { share } from '@/utils/wechart'

// if (process.env.NODE_ENV !== 'production') {
  // /* const VConsole = require('vconsole')
  // new VConsole() */
// }

// 分享配置
// share()

Vue.config.productionTip = false
Vue.prototype.$storage = storage
Vue.prototype.$reg = regexp
Vue.prototype.$requestHandler = requestHandler
Vue.component(AppContainer.name, AppContainer)
Vue.use(VueClipboard)
Vue.use(directives)
Vue.use(Icon)

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})