import Api from '^'
import storage from './storage'

export const getToken = () => storage.get('token')

export const setToken = () => storage.set('token')

export const removeToken = () => storage.remove('token')

export const toAuth = () => {
  const sso = Api.sso
  const redirect = encodeURIComponent(`${sso.redirect_uri}?url=${encodeURIComponent(sso.login_url)}`)
  location.href = `${sso.auth_url}?appid=${sso.corpID}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base#wechat_redirect`
}
